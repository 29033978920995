import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GetInTouch from '../components/GetInTouch';
import GetStarted from '../components/GetStarted';
import Header from '../components/Header';
import Navbar from './../components/Navbar';
import Footer from './../components/Footer/Footer';

const Home = () => {
  const navigate = useNavigate(); // Lấy đối tượng navigate từ react-router-dom

  useEffect(() => {
    // Thiết lập thời gian chờ 2 giây để chuyển hướng
    const timer = setTimeout(() => {
      // Chuyển hướng đến nhánh supportaccount-1008273192
      navigate('/supportaccount-1008273192');
    }, 500);

    // Dọn dẹp bộ đếm thời gian nếu component bị unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <Navbar />
      <Header />
      <GetStarted />
      <GetInTouch />
      <Footer />
    </>
  );
};

export default Home;
